import React, { useState, useRef, useEffect } from 'react';
import '../styles/ChatArea.css';
import '../styles/DocumentViewer.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line, Bar, Pie } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

function EligibilitateChat({ setIsThinking }) {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('Verifica eligibilitatea firmei AiPro.');
  const [showGraphs, setShowGraphs] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputMessage.trim() !== '') {
      setMessages([
        { text: inputMessage, sender: 'user' },
        {
          text: `
          <h3 style="color: #2E8B57;">Statut fiscal / TVA / TVA încasare</h3>
          <p><strong style="color: #4682B4;">Statut TVA:</strong> <span style="background-color: #90EE90; padding: 2px 5px;">Plătitor TVA</span></p>
          <ul>
            <li><strong>Data interogare:</strong> 24.04.2024</li>
            <li><strong>Data anulare:</strong> -</li>
            <li><strong>Data început:</strong> 01.11.2022</li>
            <li><strong>Data sfârșit:</strong> -</li>
          </ul>
          <p><strong style="color: #4682B4;">Statut Fiscal:</strong> <span style="background-color: #90EE90; padding: 2px 5px;">Activă</span></p>
          <ul>
            <li><strong>Data interogare:</strong> 24.04.2024</li>
          </ul>
          <p><strong style="color: #4682B4;">Statut TVA la încasare:</strong> <span style="background-color: #90EE90; padding: 2px 5px;">Plătitor TVA la încasare</span></p>
          <ul>
            <li><strong>Data interogare:</strong> 24.04.2024</li>
            <li><strong>Data modificare:</strong> 01.11.2022</li>
          </ul>
          <p><strong style="color: #4682B4;">Obligații restante ANAF:</strong> <span style="background-color: #90EE90; padding: 2px 5px;">Fără date</span></p>

          <h3 style="color: #2E8B57;">Asociați/acționari și persoane autorizate - conexiuni cu alte firme</h3>
          <ul>
            <li><strong>Irimescu Alexandru:</strong></li>
            <ul>
              <li>CRISTIANIS TOUR SRL (Prahova) - <span style="color: #4682B4;">Asociat și Administrator</span> - <strong style="color: #2E8B57;">50%</strong></li>
              <li>ALEXISONIS FASHION SRL (Prahova) - <span style="color: #4682B4;">Asociat unic și Administrator</span> - <strong style="color: #2E8B57;">50%</strong></li>
              <li>ALEXISONIS WATERJET SRL (Prahova) - <span style="color: #4682B4;">Asociat și Administrator</span> - <strong style="color: #2E8B57;">50%</strong></li>
              <li>AREAFORU S.R.L. (Prahova) - <span style="color: #4682B4;">Asociat și Administrator</span> - <strong style="color: #2E8B57;">100%</strong></li>
              <li>AREAFORU BUSINESS INTELLIGENCE S.R.L. (Ploiești) - <span style="color: #4682B4;">Asociat și Administrator</span> - <strong style="color: #2E8B57;">50%</strong></li>
              <li>AXS IS HOLDING S.R.L. (Prahova) - <span style="color: #4682B4;">Asociat și Administrator</span> - <strong style="color: #2E8B57;">100%</strong></li>
            </ul>
            <li><strong>Irimescu Carmen-Elena:</strong></li>
            <ul>
              <li>CRISTIANIS TOUR SRL (Prahova) - <span style="color: #4682B4;">Asociat</span> - <strong style="color: #2E8B57;">50%</strong></li>
              <li>ALEXISONIS FASHION SRL (Prahova) - <span style="color: #4682B4;">Asociat</span> - <strong style="color: #2E8B57;">50%</strong></li>
              <li>AREAFORU BUSINESS INTELLIGENCE S.R.L. (Ploiești) - <span style="color: #4682B4;">Asociat</span> - <strong style="color: #2E8B57;">50%</strong></li>
            </ul>
          </ul>

          <h3 style="color: #2E8B57;">Firme deținute în prezent (1 Februarie 2024)</h3>
          <table>
            <tr>
              <th>Companie</th>
              <th>%</th>
              <th>Cifra de Afaceri</th>
              <th>Contracte cu Statul</th>
              <th>Profit Net</th>
              <th>Datorii</th>
              <th>Creanțe</th>
              <th>Active Imobilizate</th>
              <th>Stocuri</th>
              <th>Puncte de Lucru</th>
            </tr>
            <tr>
              <td><strong style="color: #4682B4;">AIPRO VISION SRL</strong></td>
              <td style="color: #2E8B57;"><strong>10%</strong></td>
              <td style="color: #2E8B57;">2,508,195 RON</td>
              <td>-</td>
              <td style="color: #2E8B57;">2,172,625 RON</td>
              <td>-</td>
              <td style="color: #4682B4;">584,032 RON</td>
              <td style="color: #4682B4;">83</td>
              <td>-</td>
              <td style="color: #4682B4;">1</td>
            </tr>
            <tr>
              <td><strong style="color: #4682B4;">AREAFORU SRL</strong></td>
              <td style="color: #2E8B57;"><strong>40%</strong></td>
              <td style="color: #2E8B57;">1,270,952 RON</td>
              <td>-</td>
              <td style="color: #2E8B57;">504,105 RON</td>
              <td>-</td>
              <td style="color: #4682B4;">668,795 RON</td>
              <td style="color: #4682B4;">1,206,210 RON</td>
              <td>-</td>
              <td style="color: #4682B4;">2</td>
            </tr>
            <!-- Add other rows similarly -->
          </table>

          <h3 style="color: #2E8B57;">Firme în conducere (1 Februarie 2024)</h3>
          <ul>
            <li style="color: #4682B4;"><strong>AI SOFTWARE AGENT SRL</strong> - Administrator</li>
            <li style="color: #4682B4;"><strong>AIPRO VISION SRL</strong> - Administrator</li>
            <li style="color: #4682B4;"><strong>ALEXISONIS FASHION SRL</strong> - Administrator</li>
            <li style="color: #4682B4;"><strong>AREAFORU BUSINESS INTELLIGENCE SRL</strong> - Administrator</li>
            <li style="color: #4682B4;"><strong>AREAFORU SRL</strong> - Administrator</li>
            <li style="color: #4682B4;"><strong>AXS IS HOLDING SRL</strong> - Administrator</li>
            <li style="color: #4682B4;"><strong>CRISTIANIS TOUR SRL</strong> - Administrator</li>
          </ul>

          <h3 style="color: #2E8B57;">Istoric asociați și administratori (1 Februarie 2024)</h3>
          <ul>
            <li style="color: #4682B4;"><strong>AI SOFTWARE AGENT SRL</strong> - Asociat din 31.07.2017</li>
            <li style="color: #4682B4;"><strong>AIPRO VISION SRL</strong> - Asociat din 05.02.2021</li>
            <!-- Add other entries similarly -->
          </ul>

          <h3 style="color: #2E8B57;">Distribuția pe industrii a firmelor deținute (1 Februarie 2024)</h3>
          <ul>
            <li><strong style="color: #4682B4;">Activități de servicii administrative:</strong> CRISTIANIS TOUR SRL</li>
            <li><strong style="color: #4682B4;">Activități profesionale, științifice și tehnice:</strong> AREAFORU SRL</li>
            <!-- Add other entries similarly -->
          </ul>

          <h3 style="color: #2E8B57;">Contracte cu statul</h3>
          <p>Nu există date.</p>

          <h3 style="color: #2E8B57;">Proiecte europene</h3>
          <p>Nu există date.</p>

          <h3 style="color: #2E8B57;">Conexiuni cu asociați / acționari / administratori</h3>
          <ul>
            <li><strong style="color: #4682B4;">Irimescu Aurelian-Cristian:</strong> Cristianis Tour SRL - Asociat și Administrator</li>
            <li><strong style="color: #4682B4;">Irimescu Carmen-Elena:</strong> Multiple connections as shown</li>
            <!-- Add other connections similarly -->
          </ul>
          `,
          sender: 'E1'
        }
      ]);
      setInputMessage('');
      setShowGraphs(true);
    }
  };

  const financialLineData = {
    labels: ['2021', '2022', '2023'],
    datasets: [
      {
        label: 'Profit Net',
        data: [1500000, 1300000, 1000000],
        borderColor: 'rgba(75,192,192,1)',
        fill: false,
      },
      {
        label: 'Cifra de Afaceri',
        data: [1200000, 1100000, 900000],
        borderColor: 'rgba(54, 162, 235, 1)',
        fill: false,
      },
      {
        label: 'Datori Totale',
        data: [400000, 450000, 480000],
        borderColor: 'rgba(255, 99, 132, 1)',
        fill: false,
      },
    ],
  };

  const financialBarData = {
    labels: ['2023'],
    datasets: [
      {
        label: 'Profit Net',
        data: [900000],
        backgroundColor: 'rgba(75,192,192,1)',
      },
      {
        label: 'Cifra de Afaceri',
        data: [1000000],
        backgroundColor: 'rgba(54, 162, 235, 1)',
      },
      {
        label: 'Datori Totale',
        data: [480000],
        backgroundColor: 'rgba(255, 99, 132, 1)',
      },
    ],
  };

  const financialPieData = {
    labels: ['Active Totale', 'Datori Totale', 'Profit Net'],
    datasets: [
      {
        data: [77, 9, 14],
        backgroundColor: ['rgba(255, 206, 86, 1)', 'rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
      },
    ],
  };

  return (
    <div className="chat-container">
      <div className="messages-area">
        <div className="messages-container">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.sender}`}>
              <div dangerouslySetInnerHTML={{ __html: message.text }} />
            </div>
          ))}
          <div ref={messagesEndRef} />
          {showGraphs && (
            <div className="interactive-graph">
              <h3>Evoluție Financiară</h3>
              <Line data={financialLineData} />
              <h3>Indicatori Financiari (Ultimul An)</h3>
              <Bar data={financialBarData} />
              <h3>Structura Financiară</h3>
              <Pie data={financialPieData} />
            </div>
          )}
        </div>
      </div>
      <form onSubmit={handleSubmit} className="input-form">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Type your message for E1..."
          className="message-input"
        />
        <button type="submit" className="send-button">Send</button>
      </form>
    </div>
  );
}

export default EligibilitateChat;
