import React, { useState } from 'react';
import FileAttachment from './FileAttachment';
import '../styles/KnowledgeBase.css';

function KnowledgeBase() {
  const [files, setFiles] = useState([
    { id: 1, name: 'Formulare.txt', type: 'pdf', icon: 'https://img.icons8.com/papercut/60/txt.png' },
    { id: 2, name: 'Caiet de sarcini componenta inteligenta artificiala.pdf', type: 'image', icon: 'https://img.icons8.com/papercut/60/pdf.png' },
    { id: 3, name: 'Formulare.docx', type: 'image', icon: 'https://img.icons8.com/color/48/microsoft-word-2019--v2.png' },
    { id: 4, name: '[SCN1041024/00007] 02. CS - anexe tehnice - partea 1 din 5.rar.p7s', type: 'image', icon: 'https://img.icons8.com/papercut/60/txt.png' },
    { id: 5, name: '[SCN1041024/00008] 02. CS - anexe tehnice - partea 2 din 5.rar.p7s', type: 'image', icon: 'https://img.icons8.com/papercut/60/txt.png' },
    { id: 6, name: '[SCN1041024/00009] 02. CS - anexe tehnice - partea 3 din 5.rar.p7s', type: 'image', icon: 'https://img.icons8.com/papercut/60/txt.png' },
    { id: 7, name: '[SCN1041024/00003] 05. draft contract lucrari.docx.p7s', type: 'image', icon: 'https://img.icons8.com/papercut/60/pdf.png' },
    { id: 8, name: '[SCN1041024/00010] 02. CS - anexe tehnice - partea 4 din 5.rar.p7s', type: 'image', icon: 'https://img.icons8.com/papercut/60/pdf.png' },
    { id: 9, name: '[SCN1041024/00002] 04. formulare lucrari.docx.p7s', type: 'image', icon: 'https://img.icons8.com/color/48/microsoft-excel-2019--v1.png' },
    { id: 10, name: '[SCN1041024/00009] 02. CS - anexe tehnice - partea 3 din 5.rar.p7s', type: 'image', icon: 'https://img.icons8.com/papercut/60/ppt.png' },
    { id: 11, name: '[SCN1041024/00001] 02. caiet sarcini partea generala v1.pdf.p7s', type: 'image', icon: 'https://img.icons8.com/papercut/60/txt.png' }
  ]);

  const handleFileAttach = (newFiles) => {
    const newFileObjects = newFiles.map((file, index) => ({
      id: files.length + index + 1,
      name: file.name,
      type: file.type.split('/')[0],
      icon: `https://dashboard.aipro.ro/assets/icons/${file.type.split('/')[0]}-icon.png` // Replace with actual path to your icons
    }));
    setFiles([...files, ...newFileObjects]);
  };

  const handleRemoveFile = (id) => {
    setFiles(files.filter(file => file.id !== id));
  };

  return (
    <div className="knowledge-base">
      <h2>Baza de date</h2>
      <FileAttachment onFileAttach={handleFileAttach} />
      <ul className="file-list">
        {files.map((file) => (
          <li key={file.id} className="file-item">
            <img src={file.icon} alt={file.type} className="file-icon" />
            <span className="file-name">{file.name}</span>
            <button className="close-button" onClick={() => handleRemoveFile(file.id)}>x</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default KnowledgeBase;
