import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import '../styles/FileAttachment.css';

function FileAttachment({ onFileAttach }) {
  const onDrop = useCallback((acceptedFiles) => {
    console.log('Attached files:', acceptedFiles);
    onFileAttach(acceptedFiles);
  }, [onFileAttach]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Adauga un fisier aici ...</p> :
          <p>Trageți și plasați câteva fișiere aici sau faceți click pentru a selecta fișiere</p>
      }
    </div>
  );
}

export default FileAttachment;