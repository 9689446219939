import React, { useState, useEffect } from 'react';
import '../styles/DocumentViewer.css';
import * as XLSX from 'xlsx';

function DocumentViewer() {
  const [activeDocument, setActiveDocument] = useState(null);
  const [excelData, setExcelData] = useState(null);

  const documents = [
    { id: 1, name: 'PDF 1', type: 'pdf', path: require('../docs/1.pdf') },
    { id: 2, name: 'PDF 2', type: 'pdf', path: require('../docs/2.pdf') },
    { id: 3, name: 'PDF 3', type: 'pdf', path: require('../docs/3.pdf') },
    { id: 4, name: 'PDF 4', type: 'pdf', path: require('../docs/4.pdf') },
    { id: 5, name: 'PDF 5', type: 'pdf', path: require('../docs/Specificatii_tehnice.pdf') },
    { id: 6, name: 'Excel 1', type: 'excel', path: '../docs/Book1.xlsx' },
    { id: 7, name: 'Excel 2', type: 'excel', path: '../docs/Book1.xlsx' },
    { id: 8, name: 'Excel 3', type: 'excel', path: '../docs/Book1.xlsx' },
  ];

  useEffect(() => {
    const loadExcelData = async () => {
      if (activeDocument && activeDocument.type === 'excel') {
        try {
          const response = await fetch(activeDocument.path);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          setExcelData(jsonData);
        } catch (error) {
          console.error("Error fetching the Excel file:", error);
        }
      }
    };
    loadExcelData();
  }, [activeDocument]);

  const renderDocumentContent = (doc) => {
    if (doc.type === 'pdf') {
      return (
        <iframe
          src={doc.path}
          title={doc.name}
          width="100%"
          height="600px"
          frameBorder="0"
        >
          This browser does not support PDFs. Please download the PDF to view it.
        </iframe>
      );
    } else if (doc.type === 'excel' && excelData) {
      return (
        <table className="excel-table">
          <thead>
            <tr>
              {excelData[0].map((cell, cellIndex) => (
                <th key={cellIndex}>{cell}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {excelData.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    } else if (doc.type === 'excel') {
      return (
        <div className="excel-preview">
          <a href={doc.path} download>
            Click here to download the Excel file.
          </a>
        </div>
      );
    }
  };

  return (
    <div className="document-viewer">
      <div className="document-list">
        {documents.map(doc => (
          <button 
            key={doc.id} 
            onClick={() => setActiveDocument(doc)}
            className={activeDocument === doc ? 'active' : ''}
          >
            {doc.name}
          </button>
        ))}
      </div>
      <div className="document-display">
        {activeDocument && (
          <div className="document-preview">
            <h3>{activeDocument.name}</h3>
            {renderDocumentContent(activeDocument)}
          </div>
        )}
      </div>
    </div>
  );
}

export default DocumentViewer;
