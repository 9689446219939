import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';
import ChatArea from './ChatArea';
import DocumentsChat from './DocumentsChat';
import TeamChat from './TeamChat';
import AgentsChat from './AgentsChat';
import EligibilitateChat from './EligibilitateChat';
import EvaluatorChat from './EvaluatorChat';
import '../styles/App.css';

function App() {
  const [activeChat, setActiveChat] = useState('documents');
  const [isThinking, setIsThinking] = useState(false);

  const documents = [
    {
      name: 'A',
      fields: [
        { name: 'name', value: 'John Doe' },
        { name: 'phone number', value: '' },
      ]
    },
    {
      name: 'B',
      fields: [
        { name: 'address', value: '123 Main St' },
        { name: 'email', value: '' },
      ]
    }
  ];

  return (
    <Router>
      <div className="app">
        <LeftSidebar className="left-sidebar" activeChat={activeChat} />
        <main className="main-content">
          <nav className="top-nav">
            <NavLink to="/eligibilitate" className={({ isActive }) => isActive ? "nav-button active" : "nav-button"} onClick={() => setActiveChat('eligibilitate')}>Eligibilitate</NavLink>
            <NavLink to="/documents" className={({ isActive }) => isActive ? "nav-button active" : "nav-button"} onClick={() => setActiveChat('documents')}>Completare Documente</NavLink>
            <NavLink to="/team" className={({ isActive }) => isActive ? "nav-button active" : "nav-button"} onClick={() => setActiveChat('team')}>Licitatii</NavLink>
            <NavLink to="/agents" className={({ isActive }) => isActive ? "nav-button active" : "nav-button"} onClick={() => setActiveChat('agents')}>Agenti</NavLink>
            <NavLink to="/evaluator" className={({ isActive }) => isActive ? "nav-button active" : "nav-button"} onClick={() => setActiveChat('evaluator')}>Evaluator</NavLink>
          </nav>
          <Routes>
            <Route path="/documents" element={<ChatArea isThinking={isThinking}><DocumentsChat setIsThinking={setIsThinking} /></ChatArea>} />
            <Route path="/team" element={<ChatArea isThinking={isThinking}><TeamChat setIsThinking={setIsThinking} /></ChatArea>} />
            <Route path="/eligibilitate" element={<ChatArea isThinking={isThinking}><EligibilitateChat setIsThinking={setIsThinking} /></ChatArea>} />
            <Route path="/agents" element={<ChatArea isThinking={isThinking}><AgentsChat setIsThinking={setIsThinking} /></ChatArea>} />
            <Route path="/evaluator" element={<ChatArea isThinking={isThinking}><EvaluatorChat setIsThinking={setIsThinking} /></ChatArea>} />
            <Route path="/" element={<ChatArea isThinking={isThinking}><DocumentsChat setIsThinking={setIsThinking} /></ChatArea>} />
          </Routes>
        </main>
        <RightSidebar className="right-sidebar" activeChat={activeChat} documents={documents} />
      </div>
    </Router>
  );
}

export default App;
