import React, { useState, useRef, useEffect } from 'react';
import '../styles/ChatArea.css';

function EvaluatorChat({ setIsThinking }) {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('Verifica documentele si cauta erori/ greseli si propune imbunatatiri');
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputMessage.trim() !== '') {
      setMessages([
        { text: inputMessage, sender: 'user' },
        {
          text: `
            <p><strong>Evaluator:</strong> Proiectul a fost evaluat ținând cont de calificările și experiența angajaților, precum și de conformitatea tehnică a ofertei cu cerințele caietului de sarcini.</p>
            
            <p><strong>Calificările și Experiența Angajaților:</strong></p>
            <ul>
              <li style="color: green;">George Cuculescu: 6 ani experiență ca arhitect (recomandat: 5 ani) - calificat</li>
              <li style="color: green;">Bina Dan: 7 ani experiență ca dezvoltator software (recomandat: 5 ani) - calificat</li>
            </ul>

            <p><strong>Conformitate Tehnică:</strong></p>
            <ul>
              <li style="color: green;">Chatbot-ul este conform cu cerințele de procesare a limbajului natural și de integrare cu baze de date guvernamentale.</li>
              <li style="color: green;">Interfața utilizatorului respectă specificațiile de accesibilitate și securitate.</li>
              <li style="color: green;">Funcționalitățile de învățare automată sunt bine integrate pentru îmbunătățirea continuă a răspunsurilor chatbot-ului.</li>
            </ul>

            <p><strong>Aspecte Financiare:</strong></p>
            <ul>
              <li style="color: green;">Oferta financiară este competitivă, totalizând 215,000 RON, cu TVA suplimentar de 40,850 RON.</li>
              <li style="color: green;">Cifra de afaceri anuală de 2,508,195 RON și profitul de 2,172,625 RON sunt satisfăcătoare și asigură soliditatea financiară a ofertantului.</li>
            </ul>

            <p><strong>Conformitate cu Cerințele Legale și Contractuale:</strong></p>
            <ul>
              <li style="color: green;">Declarațiile de disponibilitate ale experților cheie sunt complete și conforme cu cerințele din documentația de atribuire.</li>
              <li style="color: red;">Unul dintre experții suplimentari nu îndeplinește criteriul de experiență minimă de 5 ani pe un proiect similar, ceea ce poate afecta punctajul final al ofertei.</li>
            </ul>

            <p><strong>Concluzii:</strong></p>
            <p>Proiectul este în general bine pregătit și respectă majoritatea cerințelor esențiale. Totuși, trebuie luate măsuri pentru a asigura că toți experții implicați îndeplinesc criteriile minime de calificare. Recomand suplimentarea echipei cu un expert suplimentar care să îndeplinească aceste criterii pentru a evita riscul de penalizare în evaluarea finală.</p>
          `,
          sender: 'E2'
        },
        {
          element: (
            <div className="document-comment-pair">
              <div className="document-viewer">
                <iframe src={require('../docs/1.pdf')} title="Document 1" width="100%" height="600px" frameBorder="0">
                  This browser does not support PDFs. Please download the PDF to view it.
                </iframe>
              </div>
              <div className="comments-section">
                <h3>Comentarii document 1</h3>
                <ul>
                  <li>George Cuculescu: 6 ani experienta. Arhitect - Calificat</li>
                  <li>Bina Dan: 7 ani experienta. Software Developer - Calificat</li>
                </ul>
              </div>
            </div>
          ),
          sender: 'E2'
        },
        {
          element: (
            <div className="document-comment-pair">
              <div className="document-viewer">
                <iframe src={require('../docs/2.pdf')} title="Document 2" width="100%" height="600px" frameBorder="0">
                  This browser does not support PDFs. Please download the PDF to view it.
                </iframe>
              </div>
              <div className="comments-section">
                <h3>Comentarii document 2</h3>
                <ul>
                  <li>Specificatiile tehice respecta standardele cerute.</li>
                  <li>Oferta finaciara e competitiva si se incadreaza in buget.</li>
                </ul>
              </div>
            </div>
          ),
          sender: 'E2'
        }
      ]);
      setInputMessage('');
    }
  };

  return (
    <div className="chat-container">
      <div className="messages-area">
        <div className="messages-container">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.sender}`}>
              {message.text && <div dangerouslySetInnerHTML={{ __html: message.text }} />}
              {message.element && <div>{message.element}</div>}
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <form onSubmit={handleSubmit} className="input-form">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Verifica documentele si cauta erori/ greseli si propune imbunatatiri"
          className="message-input"
        />
        <button type="submit" className="send-button">Send</button>
      </form>
    </div>
  );
}

export default EvaluatorChat;
