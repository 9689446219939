import React, { useState, useRef, useEffect } from 'react';
import DocumentViewer from './DocumentViewer';
import InteractiveGraph from './InteractiveGraph';
import '../styles/ChatArea.css';

function TeamChat({ setIsThinking }) {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('Pregateste licitatia pentru AiPro'); // Default message here
  const [showContent, setShowContent] = useState(false);
  const messagesEndRef = useRef(null);

  const agentIcons = {
    'Manager': 'https://dashboard.aipro.ro/assets/images/1.jpg',
    'Data Analyst': 'https://dashboard.aipro.ro/assets/images/2.jpg',
    'Sales Rep': 'https://dashboard.aipro.ro/assets/images/3.jpg',
    'Expert financiar': 'https://dashboard.aipro.ro/assets/images/4.jpg',
    'Finance Officer': 'https://dashboard.aipro.ro/assets/images/5.jpg'
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages, showContent]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputMessage.trim() !== '') {
      setMessages([
        { text: inputMessage, sender: 'user' },
        {
          text: `
          <p><strong>Manager:</strong> Ca manager, trebuie să identific documentele necesare pentru participarea la licitație și să trasez sarcini pentru echipa noastră de experți, care include un analist de date, un reprezentant de vânzări și un expert financiar.</p>
          <p><strong>Documentele atașate:</strong></p>
          <ul>
            <li><strong>Formulare (1).docx</strong> - Acest document conține diverse formulare necesare pentru licitație, inclusiv:
              <ul>
                <li>Formularul 3: Declarație privind neîncadrarea în prevederile art. 60 din Legea 98/2016.</li>
                <li>Formularul 5: Formular de ofertă financiară, care include detalii despre ofertă și centralizatorul de prețuri.</li>
                <li>Formularul 6: Declarație privind obligațiile referitoare la condițiile de muncă și protecția muncii.</li>
              </ul>
            </li>
            <li><strong>Caietul de sarcini componenta inteligenta artificiala.pdf</strong> - Acest document descrie cerințele și specificațiile tehnice pentru realizarea componentei de inteligență artificială, inclusiv resursele umane necesare și profilul experților.</li>
          </ul>
          <p><strong>Sarcini pentru echipă:</strong></p>
          <ul>
            <li><strong>Data Analyst</strong> - Completarea documentelor cerute pentru depunere:
              <ul>
                <li>Revizuiește și completează toate formularele necesare din Formulare (1).docx.</li>
                <li>Asigură-te că toate datele sunt corecte și complete.</li>
                <li>Verifică cerințele specificate în caietul de sarcini și asigură-te că sunt reflectate corect în documentele de ofertă.</li>
              </ul>
            </li>
            <li><strong>Reprezentant de vânzări</strong> - Verificarea altor oferte și compararea prețurilor:
              <ul>
                <li>Accesează baza de date a companiei pentru a găsi oferte similare anterioare.</li>
                <li>Compară prețurile oferite în trecut cu prețul curent din Formularul de ofertă financiară (215,000 lei fără TVA).</li>
                <li>Asigură-te că oferta noastră este competitivă și justificată.</li>
              </ul>
            </li>
            <li><strong>Expert financiar</strong> - Completarea devizului defalcat pe serviciile din caietul de sarcini:
              <ul>
                <li>Revizuiește Formularul de ofertă financiară și Centralizatorul de prețuri.</li>
                <li>Completează devizul detaliat pe baza cerințelor specificate în caietul de sarcini.</li>
                <li>Asigură-te că toate costurile sunt incluse și corect calculate, inclusiv TVA-ul.</li>
              </ul>
            </li>
          </ul>
          <p><strong>Următorii Pași:</strong></p>
          <ul>
            <li>Finalizați toate documentele și le revizuim împreună pentru a asigura conformitatea cu cerințele din caietul de sarcini.</li>
            <li>Pregătim dosarul complet pentru depunerea la licitație înainte de termenul limită.</li>
          </ul>
          `,
          sender: 'team',
          role: 'Manager'
        },
        {
          text: `
          <p><strong>Data Analyst:</strong> În rolul analistului de date, mă voi concentra pe completarea documentelor necesare pentru depunerea la licitație, asigurându-mă că toate datele sunt corecte și complete. Iată pașii pe care îi voi urma:</p>
          <ul>
            <li><strong>Completarea Documentelor Necesare:</strong></li>
            <ul>
              <li><strong>Formularul 3</strong>: Declarație privind neîncadrarea în prevederile art. 60 din Legea 98/2016.</li>
              <li><strong>Formularul 5</strong>: Formular de ofertă financiară.</li>
              <li><strong>Formularul 6</strong>: Declarație privind obligațiile referitoare la condițiile de muncă și protecția muncii.</li>
            </ul>
            <li><strong>Verificarea Cerințelor din Caietul de Sarcini:</strong></li>
            <ul>
              <li><strong>Caietul de sarcini componenta inteligenta artificiala.pdf</strong>: Mă asigur că toate informațiile din formularele completate respectă cerințele.</li>
            </ul>
          </ul>
          `,
          sender: 'team',
          role: 'Data Analyst'
        },
        {
          text: `
          <p><strong>Sales Rep:</strong> În rolul reprezentantului de vânzări, mă voi concentra pe verificarea altor oferte similare din baza de date a companiei și compararea prețurilor pentru a ne asigura că oferta noastră este competitivă.</p>
          <ul>
            <li><strong>Verificarea Altora Oferte și Compararea Prețurilor:</strong></li>
            <ul>
              <li><strong>Accesarea Bazei de Date a Companiei:</strong> Identific oferte realizate în ultimii doi ani pentru proiecte similare.</li>
              <li><strong>Evaluarea Competitivității Ofertelor:</strong> Compar prețul oferit în prezent (215,000 lei fără TVA) cu prețurile ofertate anterior pentru proiecte similare.</li>
            </ul>
          </ul>
          `,
          sender: 'team',
          role: 'Sales Rep'
        },
        {
          text: `
          <p><strong>Expert financiar:</strong> În rolul expertului financiar, mă voi concentra pe completarea devizului defalcat pe serviciile din caietul de sarcini, asigurându-mă că toate costurile sunt incluse și corect calculate.</p>
          <ul>
            <li><strong>Calculul Total al Costurilor:</strong></li>
            <ul>
              <li>Costuri totale pentru Project Manager: 30,000 lei</li>
              <li>Costuri totale pentru Arhitect: 24,000 lei</li>
              <li>Costuri totale pentru Dezvoltatori Software: 60,000 lei</li>
              <li>Costuri Administrative și Suport: 20,000 lei</li>
              <li>Alte Costuri: 10,000 lei</li>
              <li>Total costuri: 144,000 lei</li>
              <li>TVA (19%): 27,360 lei</li>
              <li>Total cu TVA: 171,360 lei</li>
            </ul>
          </ul>
          `,
          sender: 'team',
          role: 'Expert financiar'
        }
      ]);
      setInputMessage('');
      setShowContent(true);
    }
  };

  return (
    <div className="chat-container">
      <div className="messages-area">
        <div className="messages-container">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.sender}`}>
              {message.sender === 'team' && (
                <div className="message-header">
                  <img src={agentIcons[message.role]} alt={message.role} className="agent-icon" />
                  <div className="team-member-role">{message.role}</div>
                </div>
              )}
              <div className="message-text" dangerouslySetInnerHTML={{ __html: message.text }} />
            </div>
          ))}
          {showContent && (
            <>
              <DocumentViewer />
              <InteractiveGraph />
            </>
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <form onSubmit={handleSubmit} className="input-form">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Scrie mesajul pentru echipă..."
          className="message-input"
        />
        <button type="submit" className="send-button">Trimite</button>
      </form>
    </div>
  );
}

export default TeamChat;
